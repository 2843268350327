<template>
  <section id="rekapituliasi-persetujuan-rekomendasi-sementara">
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <div class="d-flex justify-content-between mb-2">
            <h3>
              No. Surat Permohonan : {{ clearanceData.letter_number }}
            </h3>
            <div>
              <b-link
                v-if="userData.group_id > 59 && userData.group_id < 70"
                :to="{ name: 'buat-surat-rekomendasi', params : {id: clearanceId} }"
                class="btn btn-info btn-sm"
              >
                Buat Surat Rekomendasi
              </b-link>
            </div>
          </div>

          <!-- table -->
          <vue-good-table
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: false,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field == 'tanggal_surat'">
                {{ props.row.tanggal_surat | formatDate }}
              </span>
              <div v-else-if="props.column.field == 'url_kominfo'">
                <b-button
                  v-b-tooltip.hover
                  variant="info"
                  size="sm"
                  class="mr-25 mb-25"
                  title="Preview Surat Kominfo"
                  @click="openFile(props.row.url_kominfo)"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="12"
                  />
                </b-button>
                <b-link
                  v-if="props.row.kominfo_sign === 1 && props.row.panrb_sign === 1"
                  v-b-tooltip.hover
                  :href="getDoc(props.row.url_kominfo)"
                  class="btn btn-sm btn-success mr-25 mb-25"
                  target="_blank"
                  title="Download Surat Kominfo"
                >
                  <feather-icon
                    icon="DownloadCloudIcon"
                    size="12"
                  />
                </b-link>
                <span
                  v-if="props.row.kominfo_sign === 0"
                >
                  <b-button
                    v-if="userData.group_id === 42"
                    v-b-tooltip.hover
                    variant="outline-warning"
                    size="sm"
                    class="m-25"
                    @click="doTte(props.row, 'KOMINFO')"
                  >Berikan TTE</b-button>
                  <b-button
                    v-else
                    variant="outline-secondary"
                    size="sm"
                    class="m-25"
                  >Menunggu TTE Dirjen</b-button>
                </span>
              </div>
              <div v-else-if="props.column.field == 'url_panrb'">
                <b-button
                  v-b-tooltip.hover
                  variant="info"
                  size="sm"
                  class="mr-25 mb-25"
                  title="Preview Surat MenpanRB"
                  @click="openFile(props.row.url_panrb)"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="12"
                  />
                </b-button>
                <b-link
                  v-if="props.row.kominfo_sign === 1 && props.row.panrb_sign === 1"
                  v-b-tooltip.hover
                  :href="getDoc(props.row.url_panrb)"
                  class="btn btn-sm btn-success mr-25 mb-25"
                  target="_blank"
                  title="Download Surat MenpanRB"
                >
                  <feather-icon
                    icon="DownloadCloudIcon"
                    size="12"
                  />
                </b-link>
                <span
                  v-if="props.row.panrb_sign === 0"
                >
                  <b-button
                    v-if="userData.group_id === 41"
                    v-b-tooltip.hover
                    variant="outline-warning"
                    size="sm"
                    class="m-25"
                    @click="doTte(props.row, 'PANRB')"
                  >Berikan TTE</b-button>
                  <b-button
                    v-else
                    variant="outline-secondary"
                    size="sm"
                    class="m-25"
                  >Menunggu TTE Deputi</b-button>
                </span>
              </div>
              <div v-else-if="props.column.field == 'url_bapenas'">
                <template v-if="props.row.url_bapenas">
                  <b-button
                    v-b-tooltip.hover
                    variant="info"
                    size="sm"
                    class="mr-25 mb-25"
                    title="Preview Surat Bappenas"
                    @click="openFile(props.row.url_bapenas)"
                  >
                    <feather-icon
                      icon="FileIcon"
                      size="12"
                    />
                  </b-button>
                  <b-link
                    v-if="props.row.kominfo_sign === 1 && props.row.panrb_sign === 1 && props.row.bapenas_sign === 1"
                    v-b-tooltip.hover
                    :href="getDoc(props.row.url_bapenas)"
                    class="btn btn-sm btn-success mr-25 mb-25"
                    target="_blank"
                    title="Download Surat Bappenas"
                  >
                    <feather-icon
                      icon="DownloadCloudIcon"
                      size="12"
                    />
                  </b-link>
                  <span
                    v-if="props.row.bapenas_sign === 0"
                  >
                    <b-button
                      v-if="userData.group_id === 43"
                      v-b-tooltip.hover
                      variant="outline-warning"
                      size="sm"
                      class="mr-25 mb-25"
                      @click="doTte(props.row, 'BAPENAS')"
                    >Berikan TTE</b-button>
                    <b-button
                      v-else
                      variant="outline-secondary"
                      size="sm"
                      class="mr-25 mb-25"
                    >Menunggu TTE Deputi Bappenas</b-button>
                  </span>
                </template>
              </div>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-card>

      </b-col>
      <!-- post -->
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-embed
          :source="fileSurat"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-tte"
      ref="modal-tte"
      hide-footer
      centered
      scrollable
      size="lg"
      title="Berikan Tanda Tangan Elektronik dengan E-Sign BSRE"
      @hidden="resetTte()"
    >
      <b-card-text>
        <form @submit.prevent="uploadTte">
          <b-row>
            <b-col
              md="12"
            >
              <b-form-group
                label="NIK"
                label-for="tte_nik"
              >
                <b-form-input
                  v-model="tte_nik"
                  name="tte_nik"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
            >
              <b-form-group
                label="Passphrase"
                label-for="tte_pwd"
              >
                <b-form-input
                  v-model="tte_pwd"
                  type="password"
                  name="tte_pwd"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="errorStat"
              cols="12"
            >
              <b-alert
                variant="danger"
                show
              >
                <h4 class="alert-heading">
                  Error Found
                </h4>
                <div class="alert-body">
                  <ul v-if="typeof errorMsg === 'object'">
                    <li
                      v-for="(item, index) in errorMsg"
                      :key="index"
                    >
                      {{ `${index}: ${item}` }}
                    </li>
                  </ul>
                  <span v-else>{{ errorMsg }}</span>
                </div>
              </b-alert>
            </b-col>
            <b-col
              md="12"
              class="mb-1"
            >
              <b-button
                variant="success"
                class="mt-0 mt-md-1"
                size="md"
                type="submit"
              >
                <span>Berikan TTE</span>
              </b-button>
              <b-button
                v-if="dataSaved"
                variant="flat-success"
                size="sm"
                class="mt-0 mt-md-1 ml-50"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  size="14"
                /> TTE Berhasil Disimpan
              </b-button>
            </b-col>
          </b-row>
        </form>
      </b-card-text>
    </b-modal>

  </section>

</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import {
  BRow, BButton, BModal, BCol, BCard, BCardText, BLink,
  BFormGroup, BFormInput, VBTooltip, VBModal, BAlert,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import stColors from '@/vars/statusColors'
import tableColumns from '@/vars/suratDetailColumns'

// import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    VuePdfEmbed,
    BCardText,
    BButton,
    BLink,
    BCard,
    BModal,
    BRow,
    BCol,
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BAlert,
    // VuePdfApp,
  },
  setup() {
    const userData = getUserData()
    const yearBudget = localStorage.getItem('tahunAnggaran')
    const { newColumns } = tableColumns()

    const {
      activityColors, activityColorsNew, clearanceColors, statusColors, verificationColors,
    } = stColors()

    const columns = newColumns

    let sortCol = 'tanggal_surat'
    if (userData.group_id === 41) {
      sortCol = 'panrb_sign'
    } else if (userData.group_id === 42) {
      sortCol = 'kominfo_sign'
    } else if (userData.group_id === 43) {
      sortCol = 'bapenas_sign'
    }

    const serverParams = {
      tahun_anggaran: yearBudget,
      sort_by: [
        {
          column: sortCol,
          asc_desc: 'asc',
        },
      ],
      start: 0,
      length: 30,
    }

    return {
      userData,
      yearBudget,
      activityColors,
      activityColorsNew,
      clearanceColors,
      statusColors,
      verificationColors,
      serverParams,
      columns,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role < 11 || role > 69) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      clearanceId: '',
      clearanceData: '',
      fileSurat: '',
      isLoading: false,
      userData: getUserData(),
      referenceData: {
        ref_instansi: [],
      },
      pdfConfig: {
        toolbar: {
          toolbarViewerRight: {
            openFile: false,
            print: false,
            download: false,
            viewBookmark: false,
          },
        },
      },
      totalRecords: 0,
      length: 100,
      rows: [],
      dataSaved: false,
      errorStat: false,
      errorMsg: '',
      tteData: '',
      tteDir: '',
      tte_nik: '',
      tte_pwd: '',
    }
  },
  created() {
    this.clearanceId = this.$route.params.id
    this.$http.get('/clearance', {
      params: {
        token: localStorage.getItem('userToken'),
        clearance_id: this.clearanceId,
      },
    })
      .then(res => {
        this.clearanceData = res.data.data
      })
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    statusVariant(status) {
      if (this.statusColors[status]) {
        return this.statusColors[status]
      }
      return {
        title: status,
        class: 'light-info',
      }
    },
    openFile(value) {
      this.fileSurat = `${process.env.VUE_APP_API_URL}${value}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    doTte(appData, select) {
      this.tteData = appData
      this.tteDir = select
      this.$refs['modal-tte'].show()
    },
    resetTte() {
      this.tteData = ''
      this.tteDir = ''
    },
    uploadTte() {
      document.getElementById('loading-bg').style.display = 'block'
      const metaUtama = {
        surat_id: this.tteData.surat_id,
        satker: this.tteDir,
        nik: this.tte_nik,
        password: this.tte_pwd,
      }
      this.$http.post('/generate/e-sign', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            document.getElementById('loading-bg').style.display = 'none'
            this.dataSaved = true

            setTimeout(() => {
              this.dataSaved = false
              this.doFilter()
              this.$refs['modal-tte'].hide()
            }, 2000)
          } else {
            document.getElementById('loading-bg').style.display = 'none'
            this.errorStat = true
            this.errorMsg = res.data.error
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.errorStat = true
          this.errorMsg = (error.response.data.message !== 'Validation Failed') ? error.response.data.message : error.response.data.error
        })
    },
    loadItems() {
      document.getElementById('loading-bg').style.display = 'block'
      this.rows = []
      this.$http.get('/surat/get-surat-rekomendasi', {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.clearanceId,
        },
      })
        .then(res => {
          this.rows = []
          if (res.data.status === 'success') {
            this.errorStat = false
            this.rows = res.data.data
            this.totalRecords = res.data.data.length
          } else {
            this.totalRecords = 0
          }
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.rows = []
          this.totalRecords = 0
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
